import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// axios.defaults.headers.common["apikey"] = config.exchangeRatesAPIKEY;
export interface State {
  appId: string;
  apiKey: string;
  appName: string;

}

const initialState: State = {
  appId: "",
  apiKey: "",
  appName: "",
};

export const notifySlice = createSlice({
  name: "Notify",
  initialState,
  reducers: {
    updateAppId: (state, action: PayloadAction<string>) => {
      state.appId = action.payload;
    },
    updateAPIKey: (state, action: PayloadAction<string>) => {
      state.apiKey = action.payload;
    },
    updateAppName: (state, action: PayloadAction<string>) => {
      state.appName = action.payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  updateAppId,
  updateAPIKey,
  updateAppName
} = notifySlice.actions;

export default notifySlice.reducer;
