import axios from "axios";
import "./NotifyWidget.css";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdNotificationsActive } from "react-icons/md";

// declare const window:any;

const NotifyWidget = ({ apiKey, bgColor, iconColor, textColor, wallet }) => {
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [discord, setDiscord] = useState("");
  //   const [telegram, setTelegram] = useState("");
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  const subscribe = async () => {
    if(!wallet) return;
    if (!email && !discord) return;
    const isValid = validateEmail(email);
    if (!isValid) return alert("Invalid email");
    try {
      setLoading(true);
      await axios.post("/subscribe", {
        email,
        discord,
        apiKey,
        wallet,
      });
      alert("Successful");
      setLoading(false);
      setSubscribed(true);
      window.localStorage.setItem("canza_notify", true);
    } catch (error) {
      console.error(error);
      setLoading(false);
      alert("Unsuccessful");
    }
  };

  const checkSubscribed = async () => {
    return window.localStorage.getItem("canza_notify");
  };

  useEffect(() => {
    if(wallet){
      checkSubscribed().then((res) => {
        if (res) {
          setSubscribed(true);
        } else {
          setOpen(true)
        }
      });
    }
  }, [wallet]);

  return (
    <div>
      <button className="p-2" onClick={() => setOpen(true)}>
        <MdNotificationsActive
          size={24}
          color={iconColor ? iconColor : "white"}
        />
      </button>
      {open && (
        <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm flex justify-end items-center">
          <div
            className=" h-full notifications"
            style={{
              backgroundColor: bgColor,
              color: textColor,
            }}
          >
            <div className="flex justify-between notifications-header">
              <div className="flex">
                <p className="font-bold">Notifications</p>
              </div>
              <button onClick={close}>
                <AiOutlineClose size={20} color={textColor} />
              </button>
            </div>

            {subscribed ? (
              <div className="p-6 w-full justify-center items-center">
                <p className="text-center mt-6 font-bold">
                  You are already subscribed !!
                </p>
                <p className="mt-5 text-center">
                  <small>
                    You will receive feature updates and other notifications
                    from Canza Notify.
                  </small>
                </p>
                <br />
                <a
                  href=" https://discord.com/invite/JddhFeYUGB"
                  rel="noreferrer"
                  target="_blank"
                  className="mb-4 text-orange-600 "
                >
                  <small>Join Our Discord Server</small>
                </a>
                <br />
                <a
                  href="https://t.me/canzafinance"
                  rel="noreferrer"
                  target="_blank"
                  className="mb-4 text-orange-600 "
                >
                  <small>Join Telegram</small>
                </a>
              </div>
            ) : (
              <div className="p-2 mt-4">
                <input
                  type="text"
                  placeholder="Wallet"
                  value={wallet}
                  className="bg-white p-2 w-full rounded outline-none"
                />
                <br />
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-white p-2 w-full rounded outline-none"
                />
                <br />
                <br />
                <input
                  type="text"
                  placeholder="Discord UserId"
                  value={discord}
                  onChange={(e) => setDiscord(e.target.value)}
                  className="bg-white p-2 w-full rounded outline-none"
                />
                <a
                  href="https://deon-achuo-1.gitbook.io/user-tutorial"
                  rel="noreferrer"
                  target="_blank"
                  className="mb-4 text-orange-600 "
                >
                  <small>How to get your Discord ID</small>
                </a>
                <br />
                {/* <div className="flex justify-between p-2">
                  <p>Discord</p>
                  <Switch />
                </div>
                <div className="flex justify-between p-2">
                  <p>Telegram</p>
                  <Switch />
                </div> */}
                <br />
                <button
                  className={`${(email || discord) ? 'bg-orange-600' : "bg-gray-400"} p-1 w-full rounded text-white`}
                  onClick={subscribe}
                >
                  {loading ? "Loading..." : "Subscribe"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// function Switch() {
//   const [isChecked, setIsChecked] = useState(false);

//   const handleChange = () => {
//     setIsChecked(!isChecked);
//   };

//   return (
//     <label className="flex items-center cursor-pointer">
//       <div className="relative">
//         <input
//           type="checkbox"
//           className="hidden"
//           checked={isChecked}
//           onChange={handleChange}
//         />
//         <div
//           className={`toggle__line w-10 h-5 ${
//             isChecked ? "bg-orange-600" : "bg-gray-400"
//           } rounded-full shadow-inner`}
//         ></div>
//         <div
//           className={`toggle__dot absolute w-5 h-5 bg-white rounded-full shadow inset-y-0 left-0 ${
//             isChecked ? "translate-x-6" : "translate-x-0"
//           } transition-transform duration-300`}
//         ></div>
//       </div>
//     </label>
//   );
// }

export default NotifyWidget;
