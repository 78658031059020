import React, { FC } from "react";
import { Link } from "react-router-dom";
import {IoIosArrowBack} from "react-icons/io"
import {AiFillSound, AiFillSetting} from "react-icons/ai"
import { useSelector } from "react-redux";

interface MainLayoutProps {
  children: React.ReactNode;
}
const Layout: FC<MainLayoutProps> = ({ children }) => {
   
  const {appId} = useSelector((state:any) => state.notify)

  return (
    <div className="flex w-full h-screen">
      <div className=" flex flex-initial w-60 bg-orange-600 text-white h-full flex-col">
       <div className="p-3">
       <Link to="/">
          <button className="flex"> <IoIosArrowBack size={24}/>  <span className="ml-2 hover:font-bold">Back</span></button>
        </Link>
       </div>
        <br />
        <br />
        <Link to={`/app/${appId}`}>
          <button className="pl-6 pt-2 pb-2 w-full hover:bg-orange-200 mb-6 flex items-center">
            <AiFillSound/>
            <p className="ml-2">Notify</p>
            </button>
        </Link>
        <Link to={`/app/settings/${appId}`}>
          <button className="pl-6 pt-2 pb-2 w-full hover:bg-orange-200 mb-6 flex items-center">
            <AiFillSetting/>
            <p className="ml-2">Settings</p>
          </button>
        </Link>
      </div>
      <div className="flex-auto p-6 bg-gray-100">{children}</div>
    </div>
  );
};

export default Layout;
