import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlinePlusSm } from "react-icons/hi";
import axios from "axios";
import { toast } from "react-toastify";
import NotifyWidget from "../../components/NotifyWidget/NotifyWidget";

declare const window: any;

const Home = () => {
  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [appName, setAppName] = useState<string>();
  const [apps, setApps] = useState<any>([]);
  const [showBroadcast, setBroadcast] = useState<boolean>(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>();

  

  const getEmail = async () => {
    return await window.localStorage.getItem("notify_email");
  };

  const registerApp = async () => {
    if (!appName) return;
    try {
      setLoading(true);
      await axios.post("/register_app", { appName });
      toast.success("App registratered successfully");
      setAppName("");
      setLoading(false);
      getApps();
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("App registration failed");
    }
  };

  const sendMessage = async () => {
    if (!message) return;
    try {
      setLoading(true);
      await axios.post("/broadcast_telegram", { message });
      toast.success("Message registratered successfully");
      setMessage("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("App registration failed");
    }
  };


  const syncBot = async () => {
    try {
      setSyncLoading(true);
      await axios.get("/store_channels");
      toast.success("Telegram bot synced successfully");
      setMessage("");
      setSyncLoading(false);
    } catch (error) {
      setSyncLoading(false);
      console.error(error);
      toast.error("App registration failed");
    }
  };

  const getApps = async () => {
    await axios.get("/apps").then((res: any) => {
      if (res.data.length) {
        setApps(res.data);
      }
    });
  };

  useEffect(() => {
    getEmail().then((email: any) => setEmail(email));
    getApps();
  }, []);

  const logout = async () => {
    try {
      await window.localStorage.removeItem("notify_user");
      window.location.reload();
    } catch (error) {}
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex w-full p-4 bg-orange-600 justify-center items-center">
        <div className="flex justify-between w-9/12 items-center">
          <h1 className="font-bold text-2xl text-white">Canza Notify</h1>
          <div className="flex items-center">
            <p className="text-white">
              <small>{email}</small>
            </p>
            <button
              className="ml-2 rounded text-orange-600 bg-white pl-2 pr-2 pt-1 pb-1"
              onClick={logout}
            >
              <small> Sign Out</small>
            </button>
           
            <NotifyWidget
              wallet={"0xBE95b366edaE5d666f4666896c7F0Fea5156C2Fd"}
              bgColor={"#ddd"}
              apiKey={"02ec65d8-b312-4e61-beaa-c21b1b9998a6"}
              iconColor={"white"}
              textColor={"black"}
            />
          </div>
        </div>
      </div>
      <div  className="w-full  bg-white-100 flex" style={{ 
        marginLeft: 260
       }} >
        <button onClick={() => setBroadcast(false)} className="ml-2 rounded text-orange-600 bg-white pl-2 pr-2 pt-1 pb-1">
          <small>Apps</small>
        </button>
        <button onClick={() => setBroadcast(true)} className="ml-2 rounded text-orange-600 bg-white pl-2 pr-2 pt-1 pb-1">
          <small> Telegram Broadcast</small>
        </button>
      </div>
      {showBroadcast ? (
        <div
          className="w-full bg-gray-100 flex flex-col"
          style={{
            height: "93vh",
          }}
        >

         <div className="flex">
         <p className="font-bold text-lg mt-5" style={{ 
            marginLeft: 270
           }} >Telegram Broadcast</p>
              <button
                className="bg-orange-600 text-white p-2 flex justify-center items-center rounded hover:font-bold mt-5 ml-5"
                onClick={syncBot}
              >
                {syncLoading ? (
                  "Loading..."
                ) : (
                  <span className=" flex items-center">Sync Bot</span>
                )}
              </button>
         </div>
          <div className="w-full flex justify-center items-center p-4 flex-col">
            <div className="w-3/4">
              <textarea
                placeholder="Message"
                rows={10}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="bg-white w-10/12 p-2 rounded focus:outline-orange-600"
              />
              <button
                className="bg-orange-600 text-white p-2 flex justify-center items-center w-10/12 rounded hover:font-bold mt-5"
                onClick={sendMessage}
              >
                {loading ? (
                  "Loading..."
                ) : (
                  <span className=" flex items-center">Send</span>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="w-full bg-gray-100 flex flex-col"
          style={{
            height: "93vh",
          }}
        >
          <div className="w-full flex justify-center items-center pt-4">
            <input
              type="text"
              placeholder="App Name"
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
              className="bg-white w-7/12 p-2 rounded focus:outline-orange-600"
            />
            <button
              className="ml-3 bg-orange-600 text-white p-2 rounded hover:font-bold"
              onClick={registerApp}
            >
              {loading ? (
                "Loading..."
              ) : (
                <span className=" flex items-center">
                  <HiOutlinePlusSm size={24} />
                  Create
                </span>
              )}
            </button>
          </div>
          <div className="w-full flex justify-center items-center pt-6">
            <div className="flex  w-8/12 ">
              {apps.map((app: any, index: number) => (
                <div
                  onClick={() => navigate(`/app/${app._id}`)}
                  key={index}
                  className="p-12 bg-white w-3/12 rounded p-6 w-full ml-4 hover:border-gray-200 hover:border cursor-pointer"
                >
                  <p className="font-bold">{app.appName}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
