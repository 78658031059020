import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./pages/Home/Home";
import Login from "./pages/Auth/Login";
import Dapp from "./pages/App/Dapp";
import Setting from "./pages/Settings/Setting";

declare const window: any;

function App() {
  const [token, setToken] = useState<string>();
  const getToken = async () => {
    return await window.localStorage.getItem("notify_user");
  };

  useEffect(() => {
    getToken().then((_token) => {
      setToken(_token);
    });
  }, [token]);

  return (
    <Router>
      {token ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/app/:id" element={<Dapp />} />
          <Route path="/app/settings/:id" element={<Setting />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      )}

      <ToastContainer />
    </Router>
  );
}

export default App;
