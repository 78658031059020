/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { updateAPIKey, updateAppId, updateAppName } from '../redux/reducers/notifyReducer';

declare const window:any;

const useData = (id:any) => {
  const dispatch = useDispatch()
  const {apiKey, appName} = useSelector((state:any) => state.notify)

  const getApp = async () => {
        try {
        dispatch(updateAppId(String(id)))
        let app = await axios.get(`/app/${id}`);
        dispatch(updateAPIKey(String(app.data.apiKey)))
        dispatch(updateAppName(String(app.data.appName)))
        } catch (error) {
        console.error(error)
        }
  }


    useEffect(() =>{
        getApp();
    }, [id])

  return {apiKey, appName}
}

export default useData