import React from "react";
import Layout from "../../layout/Layout";
import { BiSolidCopy } from "react-icons/bi";
import { toast } from "react-toastify";
import useData from "../../hoooks/useData";
import { useParams } from "react-router-dom";

const Setting = () => {
  let { id } = useParams();
  const {appName, apiKey} = useData(id)

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      toast.success("Key Copied")
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };


  return (
    <Layout>
      <div>
        <p>
          {appName} {">"} <span className="font-bold">Settings</span>
        </p>
        <br />
        <br />
        <div>
          <p className="font-bold">API Key</p>
          <div className="flex items-center">
            <div className="border w-5/12 p-2 rounded mt-4 bg-white">
              {apiKey}
            </div>
            <button className="ml-2 mt-3" onClick={handleCopy}>
              <BiSolidCopy size={24} />
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Setting;
