import React, { useState } from 'react'
import Layout from '../../layout/Layout';
import {IoIosSend} from "react-icons/io"
import { useParams } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import useData from '../../hoooks/useData';

const Dapp = () => {
  let { id } = useParams();
  const {apiKey, appName} = useData(id)
  const [subject, setSubject] = useState<string>()
  const [message, setMessage] = useState<string>()
  const [loading, setLoading] = useState<boolean>()


  const notify = async ()=> {
    try {
      setLoading(true)
      await axios.post('/send', {
        message, subject, appId:apiKey
      })
      toast.success("Message sent successfully")
      setLoading(false)
    } catch (error) {
      console.error(error)
      toast.error("Something went wrong")
      setLoading(false)
    }
  }

  

  return (
    <Layout>
        <div>
            <p className='font-bold text-3xl'>{appName}</p>
            <div  className='flex flex-col w-8/12 gap-2 mt-6'>
                 <input type="text" value={subject} onChange={e => setSubject(e.target.value)}  placeholder='Subject'  className="border p-3 rounded focus:outline-orange-600" />
                 <textarea value={message} onChange={e => setMessage(e.target.value)} className="p-3 rounded   focus:outline-orange-600" placeholder="Message" cols={30} rows={10}></textarea>
                 <button className='bg-orange-600 rounded p-2 text-white hover:font-bold flex justify-center items-center' onClick={notify}>
                  {
                    loading ? "Loading ..." : (<><span className='mr-1'>Send</span> <IoIosSend size={20}/></>)
                  }
                   </button>
            </div>
        </div>
    </Layout>
  )
}

export default Dapp