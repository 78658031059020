import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
// import loader from "../../assets/loader.gif";

declare const window: any;

const Login = () => {

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const login = async () => {
    try {
      setLoading(true);
      const result = await axios.post("/login", {
        email: email,
        password: password,
      });
      await window.localStorage.setItem("notify_user", result.data.token);
      await window.localStorage.setItem("notify_email", result.data.email);
      window.location.reload();

      setLoading(false);
    } catch (error) {
      console.log(error);

      toast.error("Invalid login details !!");
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-orange-100">
      <div className="flex flex-col gap-4 rounded  bg-orange-600 w-3/12 h-80 justify-center items-center">
        <h1 className="text-white font-bold text-2xl">Canza Notify</h1>
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="p-2 rounded w-10/12 outline-none"
        />
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="p-2 rounded w-10/12 outline-none"
        />
        <button
          className="bg-white rounded p-2 w-10/12 text-orange-600 font-bold"
          onClick={login}
        >
          {loading ? "Loading ..." : "Sign In"}
        </button>
      </div>
    </div>
  );
};

export default Login;
