import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import axios from "axios";

declare const window: any;

axios.defaults.baseURL = 'http://54.165.120.137:5004/api/v1/notify';
axios.defaults.headers.common = {
  "authorization": `Bearer ${window.localStorage.getItem('notify_user')}`,
  "ngrok-skip-browser-warning": true,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function // to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
